import { useContext } from 'react'
import PopupContext from '../context/popupContext'
import handleBackdropClick from './HandleBackdropClick'

import useClearPopups from '../functions/useClearPopups';

import './Popup.scss'

function Popup() {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)

    // console.log(useClearPopups)

    return (
        <div className={`popup-container ${currentPopup.isActive ? 'popup-container--active' : ''}`}>
            <div className="current-popup">{currentPopup.content}</div>
            <div className="popup-container__backdrop" onClick={useClearPopups()}></div>
        </div>
    )
}

export default Popup
