import { v4 } from 'uuid'
import { useParams } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import products from '../data/products.json'
import ProductRating from './ProductRating'
import FormContact from './FormContact'
import PopupContext from '../context/popupContext'

import './ProductPage.scss'

import electricity from '../images/icons/electricity.svg'
import efficiency from '../images/icons/efficiency.svg'
import outlet from '../images/icons/outlet.svg'
import power from '../images/icons/power.svg'

import { Link } from 'react-router-dom'

import { ReactComponent as PublicAndPrivateGarages } from '../images/icons/areas/1.svg'
import { ReactComponent as CommercialBuildings } from '../images/icons/areas/2.svg'
import { ReactComponent as HotelsAndRestaurants } from '../images/icons/areas/3.svg'
import { ReactComponent as EVDealerships } from '../images/icons/areas/4.svg'
import { ReactComponent as CommercialFleetOperators } from '../images/icons/areas/5.svg'
import { ReactComponent as EVInfrastructure } from '../images/icons/areas/6.svg'

import backgroundImageBack from '../images/background-man.webp'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

function ProductPage() {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)
    const reviewContent = <FormContact />

    const { title } = useParams()
    const [productId, variantId] = title.split('-').map(Number) // Преобразуем в числа

    const [currentProduct, setCurrentProduct] = useState(products.find((p) => p['id-product'] === productId)) // Инициализация состояния для продукта
    const [currentVariant, setCurrentVariant] = useState(currentProduct.variants.find((v) => v['id-variants'] === variantId)) // Инициализация состояния для варианта

    let photo
    try {
        const imageName = currentProduct.name.replace(/\s+/g, '-') + '.png'
        photo = require(`../images/products/${imageName}`)
    } catch (error) {
        photo = require(`../images/products/empty-photo.svg`).default
    }

    useEffect(() => {
        // Находим продукт по productId
        const product = products.find((p) => p['id-product'] === productId)

        // Если продукт найден, находим соответствующий вариант по variantId
        if (product) {
            // console.log(product)
            const variant = product.variants.find((v) => {
                // console.log(v)
                if (v['id-variant'] === variantId) {
                    return true
                }
            })

            // Обновляем состояние
            setCurrentProduct(product)
            setCurrentVariant(variant)
        }
    }, [productId, variantId]) // Запускаем эффект при изменении productId или variantId

    // console.log(currentProduct)

    function getRegularName(codeName) {
        let trueName

        switch (codeName) {
            case 'inputCurrent':
                trueName = 'Ток на входе (Вольт)'
                break
            case 'maxOutputCurrent':
                trueName = 'Макс. выходной ток (Ампер)'
                break
            case 'maxOutputPower':
                trueName = 'Макс. выходная мощность (Ватт)'
                break
            case 'powerModule':
                trueName = 'Модули зарядки'
                break
            case 'sizes':
                trueName = 'Размеры (см)'
                break
            case 'weight':
                trueName = 'Вес (кг)'
                break
            case 'price':
                trueName = 'Стоимость'
                break
            case 'rating':
                trueName = 'Рейтинг'
                break
            case 'reviewsQuantity':
                trueName = 'количество отзывов'
                break
            case 'id-variant':
                trueName = 'идентификатор варианта'
                break
            case 'variantName':
                trueName = 'Модель'
                break
        }

        return trueName
    }

    const falseArray = ['id-variant', 'maxOutputCurrent', 'rating', 'reviewsQuantity']

    return (
        <div className="product-page">
            <div className="wrapper wrapper--product-page--header wrapper--product-page">
                <div className="product-page__title">
                    <Link to="/product-list" className=" tp-light tp-dark-orange">
                        {' '}
                        {'<<<'} назад{' '}
                    </Link>
                    <h1>
                        {currentProduct?.name} {currentVariant?.variantName}
                    </h1>
                </div>

                <div className="product-page__header">
                    <div className="product-page__photo-btn">
                        <img src={photo} alt="" srcSet="" className="product-page__image" />

                        <div className="product-page__buttons">
                            {' '}
                            <div
                                className="btn btn--orange btn--orange--light-orange"
                                onClick={(evt) => {
                                    setCurrentPopup({ content: reviewContent, isActive: true })
                                }}
                            >
                                <div className="btn__text">Хочу купить!</div>
                            </div>
                            <div
                                className="btn btn--orange btn--orange--light-orange"
                                onClick={(evt) => {
                                    setCurrentPopup({ content: reviewContent, isActive: true })
                                }}
                            >
                                <div className="btn__text">Есть вопросы</div>
                            </div>
                        </div>
                    </div>
                    <div className="product-page__main-info">
                        <div className="product-card__rating">
                            {' '}
                            <ProductRating number={currentVariant?.rating} />
                            {currentVariant?.reviewsQuantity} отзывов
                        </div>

                        <ul className="product-page__features ">
                            <h4>Ключевые особенности:</h4>
                            {currentProduct.features.map((el, index) => {
                                if (index < 3) {
                                    return <li key={v4()}>{el}</li>
                                }
                            })}
                        </ul>

                        <div className="product-page__general-info">
                            <div className="product-page__inputVoltage product-page__general-info-item">
                                <div className="product-page__mini" style={{ backgroundImage: `url(${electricity})` }}></div>
                                <div className="tp-light">Напряжение на входе</div>
                                {currentProduct?.generalInfo.inputVoltage}V
                            </div>
                            <div className="product-page__inputConnection product-page__general-info-item">
                                <div className="product-page__mini" style={{ backgroundImage: `url(${outlet})` }}></div>
                                <div className="tp-light">Разъемы</div>
                                {currentProduct?.generalInfo.inputConnection}
                            </div>
                            <div className="product-page__KPD product-page__general-info-item">
                                <div className="product-page__mini" style={{ backgroundImage: `url(${efficiency})` }}></div>
                                <div className="tp-light">КПД</div>
                                {currentProduct?.generalInfo.KPD}%
                            </div>
                            <div className="product-page__outputVoltageRangeMaximum product-page__general-info-item">
                                <div className="product-page__mini" style={{ backgroundImage: `url(${power})` }}></div>
                                <div className="tp-light">Макс. напряжение на выходе</div>
                                {currentProduct?.generalInfo.outputVoltageRangeMaximum}V
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="product-page__content-container">
                <div className="wrapper wrapper--product-page wrapper--product-page-content">
                    {isBrowser ? (
                        <div>
                            <h2 className="tp-light tp-uppercase ">Технические характеристики моделей:</h2>
                            <br />
                            <div className="product-page__variants" key={v4()}>
                                {currentVariant
                                    ? Object.keys(currentVariant).map((keySample) => {
                                          //   console.log(key)
                                          if (falseArray.includes(keySample)) {
                                              return ''
                                          }

                                          return (
                                              <div key={v4()}>
                                                  <h3>{getRegularName(keySample)}</h3>
                                                  {currentProduct?.variants.map((variant) => {
                                                      // console.log(v4())

                                                      if (keySample === 'sizes') {
                                                          return <div>{variant[keySample].join('x')}</div>
                                                      }
                                                      if (keySample === 'price') {
                                                          return <div>${variant[keySample]}</div>
                                                      }

                                                      return <div key={v4()}>{variant[keySample]}</div>
                                                  })}
                                              </div>
                                          )
                                      })
                                    : ''}
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    <div className="image-block">
                        <div className="text">
                            <h3>Lorem ipsum</h3>
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, maxime sit? Rerum, minus laudantium! Veritatis libero, dicta,
                                maiores odit reiciendis beatae at mollitia quo eligendi vel officiis incidunt ad! Odio?
                            </p>
                            <br />
                            <div
                                className={`btn btn--orange btn--orange--light-orange ${isBrowser ? 'btn--sm' : ''}`}
                                onClick={(evt) => {
                                    setCurrentPopup({ content: reviewContent, isActive: true })
                                }}
                            >
                                <div className="btn__text">Есть вопросы</div>
                            </div>
                        </div>
                        <div className="image image-gradient-photo grid">
                            {currentProduct?.areas.map((el) => {
                                let image

                                switch (el) {
                                    case 'Общ. и час. гаражи':
                                        image = <PublicAndPrivateGarages />
                                        break
                                    case 'Коммерческие здания':
                                        image = <CommercialBuildings />
                                        break
                                    case 'Отели и рестораны':
                                        image = <HotelsAndRestaurants />
                                        break
                                    case 'Дилеры электромобилей':
                                        image = <EVDealerships />
                                        break
                                    case 'АЗС для электромобилей':
                                        image = <CommercialFleetOperators />
                                        break
                                    case 'Поставщики услуг':
                                        image = <EVInfrastructure />
                                        break
                                    default:
                                        image = <PublicAndPrivateGarages />
                                }

                                return (
                                    <div className="image-block__icon" key={v4()}>
                                        {image}
                                        <div className="tp-light image-block__icon-text">{el}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className="product-page__form-container">
                <div className="wrapper wrapper--product-page wrapper--product-page-content">
                    <div className="form-page-wrapper">
                        <FormContact newClassNames={'formContact--page-format'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductPage
