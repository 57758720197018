import React from 'react'
import { Link } from 'react-router-dom'

import ProductRating from './ProductRating'

function ProductCard({ el, variant }) {
    // const imageName = el.name.replace(/\s+/g, '-') + '.png'

    let photo
    try {
        const imageName = el.name.replace(/\s+/g, '-') + '.png'
        photo = require(`../images/products/${imageName}`)
    } catch (error) {
        photo = require(`../images/products/empty-photo.svg`).default
    }

    return (
        <div className="product-card">
            <Link to={`/product-page/${encodeURIComponent(el['id-product'] + '-' + variant['id-variant'])}`} className="a--black product-link-container">
                <div className='product-card__photo-container'><img src={photo} alt="" width={250} height={300} className="product-card__photo" /></div>
                <h3>{el.name}</h3>
                <div className="tp-light tp-sm">{variant.variantName}</div>
            </Link>

            <hr />
            <div className="product-card__rating">
                {' '}
                {variant.reviewsQuantity} отзывов <ProductRating number={variant.rating} />
            </div>
            <div className="product-card__price">от {variant.price}$</div>
        </div>
    )
}

export default ProductCard
