import React from 'react'

function PrivacyPolicy() {
    return (
        <div className="privacy-policy-container">
            <div className="wrapper wrapper--policy-container">Privacy Policy</div>
        </div>
    )
}

export default PrivacyPolicy
