import React from 'react'

import FaqSection from './FaqSection'

import Header from './Header'
import DownloadApp from './DownloadApp'

import './Header.scss'

function Home() {
    const faqItems = [
        {
          "title": "Почему покупать у Deltrix",
          "content": "Наше обещание вам"
        },
        {
          "title": "Бесплатная доставка в течение 3-8 дней",
          "content": "Deltrix с гордостью предлагает бесплатную доставку для всех заказов в США и Канаде. Для удаленных районов специальные методы доставки и их стоимость будут отображаться при оформлении заказа."
        },
        {
          "title": "3 года гарантии",
          "content": "Мы предлагаем ограниченную гарантию на 3 года для наших продуктов."
        },
        {
          "title": "30 дней на возврат без хлопот",
          "content": "Мы предлагаем 30-дневный возврат без хлопот."
        },
        {
          "title": "24/7 поддержка клиентов",
          "content": "Свяжитесь с нами по электронной почте supportus@deltrix.com или начните онлайн-чат."
        },
        {
          "title": "Государственные льготы",
          "content": "Покупайте в Deltrix и экономьте еще больше с государственными льготами!"
        }
      ]
      

    return (
        <div className="home-container">
            
            <Header />

            <FaqSection header="Часто задаваемые вопросы" items={faqItems}/>

            <DownloadApp />
        </div>
    )
}

export default Home
