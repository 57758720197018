import React from 'react'

import './About.scss'

import one from '../images/office-photos/Bracken-Road-Office-1-500x375.jpg'
import two from '../images/office-photos/Picture1-500x333.jpg'
import three from '../images/office-photos/Trio-Waterford-1-rotated.jpg'
import four from '../images/office-photos/Trio-Waterford-500x372.png'
import five from '../images/office-photos/Rayong-1-500x321.png'
import six from '../images/office-photos/G0219303739.jpg'

function About() {
    return (
        <div className="about-container">
            <div className="wrapper about-page-wrapper">
                <div className="about-text">
                    <h1>О нас</h1>
                    <p>
                        Deltrix Limited была основана в 2018 году, чтобы использовать мировую репутацию, опыт и производственные возможности группы. Deltrix в
                        настоящее время имеет два отдельных подразделения, которые специализируются на производстве передовых OEM-продуктов в области
                        электрической мобильности (eMobility) и киосковых решений.
                    </p>
                    <p>
                        Нас поддерживает наша материнская компания, основанная в 1983 году, с более чем 35-летним опытом производства в области электроники.
                        Наши европейские операции управляются из нашей штаб-квартиры в Сэндифорде, Дублин, Ирландия. В США мы базируемся в г. Ирвин, Калифорния.{' '}
                    </p>
                    <p>
                        Основные производственные операции находятся в Гуанчжоу, Китай, и Районг, Таиланд. Мы также имеем цех окончательной сборки и
                        тестирования в Уотерфорде, Ирландия, чтобы удовлетворить растущие потребности нашей европейской клиентской базы. Это предприятие также
                        служит нашим основным европейским складом.
                    </p>
                </div>

                <div className="about-photos">
                    <div className="about-photos__item" style={{ backgroundImage: `url(${one})` }}></div>
                    <div className="about-photos__item" style={{ backgroundImage: `url(${two})` }}></div>
                    <div className="about-photos__item" style={{ backgroundImage: `url(${three})` }}></div>
                    <div className="about-photos__item" style={{ backgroundImage: `url(${four})` }}></div>
                    <div className="about-photos__item" style={{ backgroundImage: `url(${five})` }}></div>
                    <div className="about-photos__item" style={{ backgroundImage: `url(${six})` }}></div>
                </div>
            </div>
        </div>
    )
}

export default About
