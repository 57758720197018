import React from 'react'
import './Footer.scss'

import { Link } from 'react-router-dom'

import logo from '../images/logo.svg'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

function Footer() {
    return (
        <div className="footer-container">
            <div className="wrapper wrapper--footer">
                <div className="footer__contacts">
                    <h4>Контакты</h4>
                    <div>
                        <div>
                            Телефон: <a href="tel:+77059054444">+7 (705) 905 44 44</a>
                        </div>
                        <div>
                            Email: <a href="mailto:Tuyakbaev.daniyar@deltrix.com?subject=Вопрос по продукту Deltrix">Tuyakbaev.daniyar@deltrix.com</a>
                        </div>
                    </div>
                    
                    <div className="tp-light tp-sm">г. Алматы, ул. Гоголя, 20</div>

                    {/* <img src={logo} alt="" /> */}

                    {isBrowser ? <div className="footer__logo" style={{ backgroundImage: `url(${logo})` }}></div>: ''}
                </div>

                <div className="footer__links">
                    <h4>Ссылки</h4>
                    <ul>
                        <li>
                            <Link to="/about">О нас</Link>
                        </li>
                        <li>
                            <Link to="/">FAQ</Link>
                        </li>
                        <li>
                            <Link to="/product-list">Продукты</Link>
                        </li>
                    </ul>
                </div>

                <div className="footer__links">
                    <h4>Соглашения</h4>
                    <ul>
                        <li>
                            <Link to="/privacy-policy">Политика конфиденциальности</Link>
                        </li>
                    </ul>
                </div>

                {isMobile ? <div className="footer__logo" style={{ backgroundImage: `url(${logo})` }}></div>: ''}
            </div>
        </div>
    )
}

export default Footer
