import Menu from '../components/Menu'
import Footer from '../components/Footer'
import Popup from '../components/Popup'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'
import PopupContext from '../context/popupContext'
import CartContext from '../context/CartContext'

import { useState, useEffect } from 'react'

import { Outlet, useLocation } from 'react-router-dom'
import { useContext } from 'react'

import { GiHamburgerMenu } from 'react-icons/gi'
import { IoCloseOutline } from 'react-icons/io5'

function MainLayout() {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)
    const { currentCart, setCurrentCart } = useContext(CartContext)
    
    const [isVisible, setIsVisible] = useState(false)

    const handleBackdropClick = () => {
        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    }

    const reviewContent = <Menu />

    return (
        <>
            <Popup />
            {isMobile && (
                <div
                    className="open-menu-button"
                    onClick={(evt) => {
                        // console.log('123')
                        // console.log(currentPopup)
                        if (!currentPopup.isActive) {
                            setCurrentPopup({ content: reviewContent, isActive: true })
                        } else {
                            handleBackdropClick()
                        }
                    }}
                >
                    {currentPopup.isActive ? <IoCloseOutline /> : <GiHamburgerMenu />}
                </div>
            )}
            {isBrowser && <Menu />}
            <div className="content-container">
                <Outlet />
            </div>
            <Footer />
        </>
    )
}

export default MainLayout
