import { useContext } from 'react';
import PopupContext from '../context/popupContext';

function useClearPopups() {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)

    const clearPopups = () => {
        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    };

    return clearPopups;
}

export default useClearPopups;
