import React from 'react'
import products from '../data/products.json'

import { useState, useEffect } from 'react'
import FilterContext from '../context/FiltersContext'

import { v4 } from 'uuid'

// import { CheckboxContextProvider, useCheckboxContext } from '../context/FilterContext'; // Замена 'CheckboxContext' на имя вашего контекста

function CheckboxGroupFilter({ el, filtersState, setFiltersState, index }) {
    const [selectedOption, setSelectedOption] = useState([])

    const handleOptionChange = (event, indexOfValue) => {
        const value = event.target.value
        const isChecked = event.target.checked

        // console.log(value, isChecked, indexOfValue)

        setFiltersState(filtersState => {
          return filtersState.map((itemOfState, itemOfStateIndex) => {
            if(itemOfStateIndex === index){
              itemOfState.values[indexOfValue].isChecked = isChecked
              return itemOfState
            }
            return itemOfState
          })
        })
    }

    return (
        <form>
            <h4> {el.nameOfArea}</h4>

            <div className="radio-group-filter">
                {/* <div>
                    <input
                        type="checkbox"
                        id={`${el.nameOfVariable}-anyone`}
                        name={el.nameOfVariable}
                        value={`${el.nameOfVariable}-anyone`}
                        onChange={handleOptionChange}
                        defaultChecked={true}
                    />
                    <label htmlFor={`${el.nameOfVariable}-anyone`}>Любые варианты</label>
                </div> */}

                {el.values.length > 0
                    ? el.values.map((property, indexOfValue) => {
                          // console.log(property)

                          return (
                              <div key={indexOfValue}>
                                  {/* <input type="checkbox" name={el.nameOfVariable} value={el} id={el} onChange={handleOptionChange} />
                                  <label htmlFor={el}>
                                      {el}
                                      {el.addedPrefix}
                                  </label> */}

                                  <input
                                      type="checkbox"
                                      value={property.value}
                                      name={property.value}
                                      id={property.value}
                                      onChange={(event) => handleOptionChange(event, indexOfValue)}
                                      checked={property.isChecked}
                                  />
                                  <label htmlFor={property.value}>
                                      {property.value}
                                      {el.addedPrefix}
                                  </label>
                              </div>
                          )
                      })
                    : ''}
            </div>
        </form>
    )
}

export default CheckboxGroupFilter
