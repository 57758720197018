import React from 'react'
import './DownloadApp.scss'

import imgBG from '../images/phones-background.webp'

import { ReactComponent as AppStoreButton } from '../images/icons/Download_on_the_App_Store_Badge_RU_RGB_wht_100317.svg'
import { ReactComponent as GoogleStoreApp } from '../images/icons/google-play-badge.svg'

function DownloadApp() {
    return (
        <div>
            <div className="download-block-container">
                <div className="wrapper">
                    <div className="download-block__section">
                        <div className="download-block-container__image" ></div>
                        <div className="download-block-container__buttons">
                            <div className="download-block-container__text-block">
                                <h2>Скачать приложение</h2>
                                <div className="download-block-container__text">
                                    Легко контролируйте, управляйте и оптимизируйте зарядку вашего электромобиля с телефона в любое время и в любом месте.
                                </div>
                            </div>

                            <div className="download-buttons">
                                <AppStoreButton />
                                <GoogleStoreApp />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadApp
