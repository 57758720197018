import { NavLink } from 'react-router-dom'
import React from 'react'

import FormContact from './FormContact'

import { useContext, useEffect, useState } from 'react'
import PopupContext from '../context/popupContext'
import Cart from './Cart'

import useClearPopups from '../functions/useClearPopups'

import './Menu.scss'

import logo from '../images/logo.svg'
import logoWhite from '../images/logo-white.svg'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

function Menu() {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)

    const reviewContent = <FormContact />
    const cartContent = <Cart />

    return (
        <div className="menu-container">
            <nav className="wrapper wrapper--nav">
                <NavLink to="/" end>
                    {isBrowser ? <img src={logo} alt="" /> : ''}
                    {isMobile ? <img src={logoWhite} alt="" /> : ''}
                </NavLink>

                <NavLink className={({ isActive }) => (isActive ? 'link activeLink' : 'link')} to="/" end onClick={useClearPopups()}>
                    Главная
                </NavLink>
                <NavLink className={({ isActive }) => (isActive ? 'link activeLink' : 'link')} to="/about" onClick={useClearPopups()}>
                    О нас
                </NavLink>
                <NavLink className={({ isActive }) => (isActive ? 'link activeLink' : 'link')} to="/product-list" end onClick={useClearPopups()}>
                    Купить устройство
                </NavLink>

                <div
                    className="link link-demo"
                    onClick={(evt) => {
                        setCurrentPopup({ content: reviewContent, isActive: true })
                    }}
                >
                    Свяжитесь со мной
                </div>

                <div
                    className="link link-demo"
                    onClick={(evt) => {
                        setCurrentPopup({ content: cartContent, isActive: true })
                    }}
                >
                    Корзина
                </div>
            </nav>
        </div>
    )
}

export default Menu
