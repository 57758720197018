import React, { useEffect, useState } from 'react'
import './ProductRating.scss'

import { uuidv4 } from 'uuid'

import starEmpty from '../images/icons/star--empty.svg'

function ProductRating({ number }) {
    // console.clear()
    const [arrayOfNumbers, setArrayOfNumbers] = useState([0, 0, 0, 0, 0])

    useEffect(() => {
        // Вычисляем целое число звезд
        const fullStars = Math.floor(number)
        // Вычисляем процент заполнения для последней неполной звезды
        const partialStar = (number - fullStars) * 100
        // console.log(partialStar)

        const newArray = [0, 0, 0, 0, 0]

        // Заполняем массив полными звездами
        for (let i = 0; i < fullStars; i++) {
            newArray[i] = 100
        }

        // Если есть неполная звезда, добавляем ее
        if (fullStars < 5) {
            // console.log(newArray[fullStars])
            newArray[fullStars] = partialStar
        }

        setArrayOfNumbers(newArray)
    }, [number]) // Обновляем массив при изменении `number`



    return (
        <div>
            <div className="rating-empty">
                {arrayOfNumbers.map((fill, index) => {
                                  

                    return (
                        <div key={index} className="rating-star"  style={{backgroundImage: fill < 100 ? `url(${starEmpty})` : 'unset'}} >
                            <div className="rating-star__fill" style={{ width: `${fill}%` }}></div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ProductRating
