import './App.scss'
import './components/btn.scss'
import './components/typography.scss'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

//Components
import MainLayout from './layouts/MainLayout'
import Home from './components/Home'
import ProductList from './components/ProductList'
import About from './components/About'
import ProductPage from './components/ProductPage'
import NotFound from './components/NotFound'
import PrivacyPolicy from './components/PrivacyPolicy'

//Libs
import { useState } from 'react'
import { Provider } from 'react-redux'
// import store from './stores'

// Context
import PopupContext from './context/popupContext'
import FiltersContext from './context/FiltersContext'
import CartContext from './context/CartContext'

function App() {
    const [currentPopup, setCurrentPopup] = useState({ content: '', isActive: false })
    const [currentFilters, setCurrentFilters] = useState([])
    const [currentCart, setCurrentCart] = useState([])

    return (
        <div className="App">
            <BrowserRouter>
                <CartContext.Provider value={{ currentCart, setCurrentCart }}>
                    <PopupContext.Provider value={{ currentPopup, setCurrentPopup }}>
                        <FiltersContext.Provider value={{ currentFilters, setCurrentFilters }}>
                            <Routes>
                                <Route path="/" element={<MainLayout />}>
                                    <Route index={true} element={<Home />} />

                                    <Route path="/product-list" element={<ProductList />}></Route>
                                    <Route path="/about" element={<About />}></Route>

                                    <Route path="*" element={<NotFound />} />

                                    <Route path="/product-page/:title" element={<ProductPage />} />
                                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                </Route>
                            </Routes>
                        </FiltersContext.Provider>
                    </PopupContext.Provider>
                </CartContext.Provider>
            </BrowserRouter>
        </div>
    )
}

export default App
