import React from 'react'
import headerImage from '../images/DCwbCharger.png'

import { useEffect, useState, useContext } from 'react'

import { CSSTransition } from 'react-transition-group'
import PopupContext from '../context/popupContext'

import FormContact from './FormContact'

import logo from '../images/logo-white.svg'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

function Header() {
    const [showHeaderInfo, setShowHeaderInfo] = useState(false)
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)

    const reviewContent = <FormContact />

    useEffect(() => {
        setTimeout(() => {
            setShowHeaderInfo(true)
        }, 300)
    }, [])

    return (
        <div className="header-container">
            <div className="wrapper wrapper--header">
                <div className="header__info">
                    <CSSTransition
                        in={showHeaderInfo}
                        timeout={1000} // Установите время анимации в миллисекундах
                        classNames="header-info header-info"
                        unmountOnExit
                    >
                        <div>
                        {isMobile ? <div className="header__logo" style={{ backgroundImage: `url(${logo})` }}></div>: ''}

                            <div>
                                <h1 style={{ color: 'white' }}>Приводим вас в движение!</h1>
                                <div style={{ color: 'white' }}>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro accusamus mollitia aut ipsam accusantium, iste dolorum
                                    molestiae necessitatibus aspernatur voluptatum, laudantium libero possimus repellendus fugit placeat excepturi provident
                                    nesciunt repudiandae?
                                </div>
                            </div>
                            <div
                                className="btn btn--light"
                                onClick={(evt) => {
                                    setCurrentPopup({ content: reviewContent, isActive: true })
                                }}
                            >
                                <div className="btn__text">Хочу купить!</div>

                                {/* хочу купить */}
                            </div>
                        </div>
                    </CSSTransition>
                </div>

                <div className="header__header-image"></div>
            </div>
        </div>
    )
}

export default Header
