import React from 'react'
import CartContext from '../context/CartContext'

import { useContext } from 'react'

function Cart() {
    const { currentCart, setCurrentCart } = useContext(CartContext)

  return (
    <div>Cart</div>
  )
}

export default Cart