import React from 'react';
import AccordionItem from './AccordionItem'; // Импортируем компонент из шага 1

import {v4} from 'uuid'

const Accordion = ({ items }) => {
  return (
    <div className="accordion">
      {items.map((item, index) => (
        <AccordionItem key={index} title={item.title}>
          {item.content} {/* Контент каждой секции */}
        </AccordionItem>
      ))}
    </div>
  );
};

export default Accordion;
