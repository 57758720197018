import './Form.scss'
import { useEffect, useState } from 'react'
import axios from 'axios'
import ReactInputMask from 'react-input-mask'
import ReCAPTCHA from 'react-google-recaptcha'

import { Link } from 'react-router-dom'

function Form({ headerText, buttonText, newClassNames }) {
    const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;

    

    const [nameState, setNameState] = useState('')
    const [emailState, setEmailState] = useState('')
    const [phoneState, setPhoneState] = useState('')
    const [nameOrganizationState, setNameOrganizationState] = useState('')
    const [captchaValue, setCaptchaValue] = useState(null)

    const [fullForm, setFullForm] = useState('')

    useEffect(() => {
        const message = `<b>Новая заявка от</b> ${nameState}%0A<b>Номер телефона:</b> ${phoneState}`
        setFullForm(message)
    }, [nameState, emailState, phoneState, nameOrganizationState])

    const chatID = process.env.REACT_APP_CHAT_ID;
    const botToken = process.env.REACT_APP_BOT_TOKEN;
    // console.log(chatID, botToken)

    const botUrl = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatID}&parse_mode=html&text=${fullForm}`

    function setNameStateHandler(value) {
        setNameState(value)
    }

    function handleSubmit(evt) {
        evt.preventDefault()
        // if (!captchaValue) {
        //     alert('Подтвердите, что вы не робот')
        //     return
        // }
        axios.post(botUrl, nameState).then((response) => console.log(response.status))
    }

    return (
        <form className={`formContact ${newClassNames}`} onSubmit={handleSubmit}>
            <div className="formContact__content">
                <div className="formContact__text">
                    <h2>{headerText ? headerText : 'Свяжемся с вами!'}</h2>
                    <div>Оставьте заявку в форме ниже и мы свяжемся с вами в ближайшее время!</div>
                </div>

                <br />
                <div className="input-list">
                    <input
                        type="text"
                        placeholder="Ваше имя"
                        onChange={(evt) => {
                            setNameStateHandler(evt.target.value)
                        }}
                    />
                    <ReactInputMask
                        mask="+7 (999) 999-99-99"
                        maskChar="_"
                        onChange={(evt) => {
                            setPhoneState(evt.target.value)
                        }}
                        placeholder="+7 (___) ___-__-__"
                    />
                    {/* <input
                        type="text"
                        placeholder="Название организации"
                        onChange={(evt) => {
                            setNameOrganizationState(evt.target.value)
                        }}
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        onChange={(evt) => {
                            setEmailState(evt.target.value)
                        }}
                    /> */}
                    {/* <ReCAPTCHA sitekey={captchaKey} onChange={(value) => setCaptchaValue(value)} /> */}
                    
                </div>
                <div className="tp-light tp-sm">Отправляя эту форму, вы соглашаетесь с нашей <Link target="_blank" to='/privacy-policy' className='tp-underline'>политикой конфиденциальности</Link></div>
                <br />
                <button type="submit" className="btn btn--light" style={{border: '1px solid rgba(255, 255, 255, .3)'}}>
                   <div className='btn__text '> {buttonText ? buttonText : 'Хочу узнать!'}</div>
                </button>
            </div>
        </form>
    )
}

export default Form
