import React, { useState, useEffect, useContext } from 'react'
import products from '../data/products.json'

import ProductCard from './ProductCard'
import CheckboxGroupFilter from './CheckboxGroupFilter'
import FilterContext from '../context/FiltersContext'

import './ProductList.scss'
import { v4 } from 'uuid'

function ProductList() {
    // const { currentFilters, setCurrentFilters } = useContext(FilterContext)
    const [filtersState, setFiltersState] = useState([
        { nameOfArea: 'Рабочее напряжение', nameOfVariable: 'inputVoltage', addedPrefix: 'V', values: [], checkedValues: ['all'], type: 'general' },
        {
            nameOfArea: 'Максимальная мощность на выходе',
            nameOfVariable: 'maxOutputPower',
            addedPrefix: 'W',
            values: [],
            checkedValues: ['all'],
            type: 'variable',
        },
        // {
        //     nameOfArea: 'Максимальный ток на выходе',
        //     nameOfVariable: 'maxOutputCurrent',
        //     addedPrefix: 'A',
        //     values: [],
        //     checkedValues: ['all'],
        //     type: 'variable',
        // },
    ])

    const findValuesByKey = (obj, key) => {
        let values = []

        if (Array.isArray(obj)) {
            obj.forEach((item) => {
                values = values.concat(findValuesByKey(item, key))
            })
        } else if (typeof obj === 'object' && obj !== null) {
            Object.entries(obj).forEach(([k, v]) => {
                if (k === key) {
                    values.push(v)
                } else {
                    values = values.concat(findValuesByKey(v, key))
                }
            })
        }

        return values
    }

    useEffect(() => {
        const updatedFiltersState = [...filtersState]

        for (let i = 0; i < updatedFiltersState.length; i++) {
            const values = findValuesByKey(products, updatedFiltersState[i].nameOfVariable)

            // Используем Set для исключения дубликатов
            const uniqueValues = Array.from(new Set([...values]))

            updatedFiltersState[i].values = uniqueValues.map((val) => ({
                value: val,
                isChecked: true,
            }))
        }

        setFiltersState(updatedFiltersState)
    }, [])

    const uniqueFilteredVariants = new Set()
    let arrayOfFilteredProducts

    function generateUniqueIdList() {
        const accesableProducts = new Set()

        for (let filter of filtersState) {
            if (filter.type === 'general') {
                const checkedValues = filter.values.map((value) => {
                    if (value.isChecked) {
                        return value.value
                    }
                })

                for (let product of products) {
                    // console.log(product.generalInfo[filter.nameOfVariable])
                    // console.log(checkedValues)
                    if (checkedValues.includes(product.generalInfo[filter.nameOfVariable])) {
                        accesableProducts.add(product)
                        // console.log(product, true)
                    } else {
                        // console.log(product, false)
                    }
                }
            }
        }

        const accesableVariants = new Set()

        for (let filter of filtersState) {
            const filterPasses = true

            if (filter.type !== 'general') {
                const checkedValues = filter.values.map((value) => {
                    if (value.isChecked) {
                        return value.value
                    }
                })

                for (let product of accesableProducts) {
                    let numberOfFails = 0
                    for (let variant of product.variants) {
                        
                        if (!checkedValues.includes(variant[filter.nameOfVariable])) {
                            break
                        }else {
                            accesableVariants.add(variant)
                            uniqueFilteredVariants.add([product, variant])
                            // console.log(uniqueFilteredVariants)
                            // console.log(accesableVariants)
                        }
                        
                    }
                    
                }
            }
        }

        // console.log(accesableVariants)
    }

    generateUniqueIdList()

    useEffect(
        (el) => {
            generateUniqueIdList()
        },
        [filtersState]
    )

    return (
        <div className="productList-container ">
            <div className="wrapper wrapper--product-filter">
                <div className="productList-filter">
                    {filtersState.map((el, index) => (
                        <CheckboxGroupFilter el={el} key={v4()} filtersState={filtersState} setFiltersState={setFiltersState} index={index} />
                    ))}
                </div>

                <div className="products-cards">
                    

                    {Array.from(uniqueFilteredVariants).map((info) => {
                        // console.log(info[0], info[1])
                        
                        return (
                            <ProductCard el={info[0]} variant={info[1]} key={v4()} />
                        )
                        
                    })}
                </div>
            </div>
        </div>
    )
}

export default ProductList
