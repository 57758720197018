import React from 'react'
import Accordion from './Accordion' // Импортируем компонент из шага 2

import './FaqSection.scss'

const FaqSection = ({header, items}) => {
    // console.log(header, items)

    let heightMaximum

    function getWidthHandler(evt){
        console.log(evt.target.clientHeight)

        heightMaximum = evt.target.clientHeight
    }

    return (
        <div className="faq-section-container" onClick={(evt) => getWidthHandler(evt)} >
            <div className="wrapper wrapper--faq-section">
                <div className="faq-section image-block" style={{'max-height': `${heightMaximum}px`}}>
                    <div className="text">
                        <h2>{header}</h2>
                        <Accordion items={items} />
                    </div>
                    <div className="image image-photo">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqSection
