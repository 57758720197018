import React, { useState } from 'react'
import { MdOutlineKeyboardArrowUp  } from 'react-icons/md'

const AccordionItem = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className="accordion-item">
            <div className="accordion-header" onClick={toggle} style={{ cursor: 'pointer', userSelect: 'none' }}>
                <div>{title}</div>
                {/* {title} {isOpen ? '-' : '+'}  */}
                <MdOutlineKeyboardArrowUp  className={`arrow-faq ${isOpen ? 'opened' : 'closed'}`} />
            </div>
            {isOpen && (
                <div className="accordion-content">
                    {children} {/* Здесь размещается контент аккордиона */}
                </div>
            )}
        </div>
    )
}

export default AccordionItem
